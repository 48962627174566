import { ENTERPRISE_PAGE_LINK, SOFTWARE_MINI_SITE_LINK } from "constants/links";
const WEBFLOW_PREFIXES = [
    "/ap/",
    "/w/"
];
export const WEBFLOW_TO_GENERATOR_PAGE = {
    [SOFTWARE_MINI_SITE_LINK]: "/dev",
    [ENTERPRISE_PAGE_LINK]: "/enterprise",
    "/w/crm": "/crm"
};
const WEBFLOW_PAGES_ENABLED_LOCALES = {
    [SOFTWARE_MINI_SITE_LINK]: [],
    [ENTERPRISE_PAGE_LINK]: [],
    "/w/crm": []
};
export const getWebflowLocalizedPage = (webflowPage, locale)=>{
    var _WEBFLOW_PAGES_ENABLED_LOCALES_webflowPage;
    if (!((_WEBFLOW_PAGES_ENABLED_LOCALES_webflowPage = WEBFLOW_PAGES_ENABLED_LOCALES[webflowPage]) === null || _WEBFLOW_PAGES_ENABLED_LOCALES_webflowPage === void 0 ? void 0 : _WEBFLOW_PAGES_ENABLED_LOCALES_webflowPage.includes(locale))) return null;
    const webflowPrefix = WEBFLOW_PREFIXES.find((prefix)=>webflowPage.startsWith(prefix));
    if (!webflowPrefix) return null;
    const uriWithoutPrefix = webflowPage.slice(webflowPrefix.length);
    return `${webflowPrefix}${locale}/${uriWithoutPrefix}`;
};
export const isWebflowUri = (uri = "")=>WEBFLOW_PREFIXES.some((prefix)=>uri.startsWith(prefix));
